<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col>
        <v-sheet
          color="white"
          class="pa-3"
          tile
          elevation="1"
        >
          <v-row>
            <v-col
              cols="6"
              md="2"
            >
              <v-menu
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataInicioFormatada"
                    v-mask="'##/##/####'"
                    label="Data Início"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_inicio = formatarData.api(dataInicioFormatada)"
                    @click:append="menuDataInicio = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_inicio"
                  no-title
                  class="ma-0"
                  @input="menuDataInicio = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="pl-md-0"
            >
              <v-menu
                v-model="menuDataFim"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataFimFormatada"
                    v-mask="'##/##/####'"
                    label="Data Fim"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_fim = formatarData.api(dataFimFormatada)"
                    @click:append="menuDataFim = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_fim"
                  no-title
                  class="ma-0"
                  @input="menuDataFim = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              class="ml-md-2 pt-0 pl-md-0 pt-md-2"
            >
              <v-btn
                color="info"
                class="mt-1"
                :loading="carregando"
                @click="buscarLeituras()"
              >
                <v-icon
                  left
                  v-text="'mdi-magnify'"
                />
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row
      v-if="Object.keys(leituras).length <= 0 && !carregando"
    >
      <v-col>
        <i>
          Nenhum registro encontrado
        </i>
      </v-col>
    </v-row>

    <v-row
      class="px-2"
    >
      <v-col
        v-for="leitura in leituras"
        :key="leitura.leitura_id"
        cols="6"
        md="3"
        xl="2"
        class="pa-2"
      >
        <v-card
          class="ma-0"
          :loading="carregandoDetalhesLeitura === leitura.leitura_id"
        >
          <template slot="progress">
            <v-progress-linear
              color="black"
              indeterminate
              absolute
              bottom
            />
          </template>

          <div
            height="auto"
            style="overflow: none"
            class="row-pointer"
            @click="buscarDetalhesLeitura(leitura)"
          >
            <v-img
              v-if="!imagensErro.includes(leitura.leitura_id)"
              :src="'data:image/png;base64,' + leitura.imagem"
              :lazy-src="$store.state.layout.imagemLeituraCarregando"
              @error="erroCarregarImagem(leitura.leitura_id)"
            />
            <v-img
              v-else
              :src="require('@/assets/imagens/imagem-padrao.png')"
              :lazy-src="$store.state.layout.imagemLeituraCarregando"
            />
          </div>

          <v-card-text class="pa-2 pt-0">
            <v-row
              class="fill-height mx-0 mt-n4 mb-1"
              align="end"
              justify="center"
            >
              <v-chip
                color="black"
                dark
                label
              >
                <span class="display-2 font-weight-black">{{ leitura.placa || '-------' }}</span>
              </v-chip>
            </v-row>

            <div class="caption">
              {{ leitura.data_registro | data('DD/MM/YYYY HH:mm:ss') }}
            </div>
            <div class="caption">
              {{ leitura.descricao }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <i>
          Total de registros: {{ totalRegistros }}
        </i>
      </v-col>
    </v-row>

    <v-row v-if="carregando">
      <v-col>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-model="pagina"
          :length="totalPaginas"
          :total-visible="7"
          @input="buscarLeituras"
        />
      </v-col>
    </v-row>

    <dialog-detalhes-leitura
      v-if="dialogDetalhesLeitura"
      v-model="dialogDetalhesLeitura"
      esconder-carousel
      :leitura="detalhesLeitura"
    />
  </v-container>
</template>

<script>
  import apiRelatorios from '@/api/relatorios'
  import apiLeituras from '@/api/leituras'
  import formatarData from '@/utils/formatar-data-input'
  import dayjs from 'dayjs'

  export default {

    components: {
      DialogDetalhesLeitura: () => import('@/components/dialog/leitura/DialogDetalhesLeitura'),
    },

    data () {
      return {
        leituras: [],
        dialogFiltros: false,
        detalhesLeitura: null,
        carregando: false,
        carregandoDetalhesLeitura: false,
        imagensErro: [],
        pagina: 1,
        totalPaginas: 1,
        totalRegistros: 0,
        dialogDetalhesLeitura: false,
        menuDataInicio: false,
        menuDataFim: false,
        dataInicioFormatada: null,
        dataFimFormatada: null,
        filtros: {
          data_inicio: dayjs().format('YYYY-MM-DD'),
          data_fim: dayjs().format('YYYY-MM-DD'),
        },
      }
    },

    watch: {
      'filtros.data_inicio': {
        immediate: true,
        handler (data) {
          this.dataInicioFormatada = formatarData.ptBr(data)
        },
      },
      'filtros.data_fim': {
        immediate: true,
        handler (data) {
          this.dataFimFormatada = formatarData.ptBr(data)
        },
      },
    },

    mounted () {
      this.buscarLeituras()
    },

    methods: {
      async buscarLeituras () {
        try {
          this.carregando = true
          this.dialogFiltros = false

          const filtros = { ...this.filtros }

          filtros.data_inicio = filtros.data_inicio || null
          filtros.data_fim = filtros.data_fim || null

          filtros.por_pagina = 16
          filtros.pagina = this.pagina

          const dados = await apiRelatorios.leiturasSemPlaca(filtros)
          this.leituras = dados.data.data
          this.totalPaginas = dados.data.meta.last_page
          this.totalRegistros = dados.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

      async buscarDetalhesLeitura (leitura) {
        try {
          this.carregandoDetalhesLeitura = leitura.leitura_id
          const dados = await apiLeituras.detalhes(leitura.leitura_id)
          this.detalhesLeitura = dados.data

          if (this.imagensErro.includes(leitura.leitura_id)) {
            this.detalhesLeitura.imagem_lazy_src = this.$store.state.layout.imagemLeituraCarregando
          } else {
            this.detalhesLeitura.imagem_lazy_src = 'data:image/png;base64,' + leitura.imagem
          }

          this.dialogDetalhesLeitura = true
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoDetalhesLeitura = false
        }
      },

      erroCarregarImagem (leituraId) {
        this.imagensErro.push(leituraId)
      },

    },
  }
</script>

<style lang="sass">
  .row-pointer
    cursor: pointer

  #placa
    text-transform: uppercase
</style>
