import axios from '@/plugins/axios'
import gerarQueryString from '@/utils/gerar-query-string'

export default {

  async dashboard (dados) {
    const parametros = gerarQueryString(dados) 
    return await axios.get(`dashboard/leituras?${parametros}`)
  },
  async detalhes (id) {
    return await axios.get(`leituras/${id}/detalhes`)
  },

  async criarOcorrencia (id) {
    return await axios.post(`leituras/${id}/ocorrencia`)
  },

  async exportar (id) {
    return await axios.get(`leituras/${id}/exportar`)
  },

  async corrigirPlaca (leituraId, dados) {
    return await axios.put(`leituras/${leituraId}/corrigir-placa`, dados)
  },

  async carrousel (leituraId, dados = {}) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`dashboard/carrossel/${leituraId}?${parametros}`)
  },

  async exportarImagem (imagemLeituraId) {
    return await axios.get(`imagens-leitura/${imagemLeituraId}/exportar`)
  },

  async desativar (id) {
    return await axios.delete(`leituras/${id}`)
  },

}
