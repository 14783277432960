export default {

  ptBr (data) {
    if (!data) return null

    const [ano, mes, dia] = data.split('-')
    return `${dia}/${mes}/${ano}`
  },
  formatoTZ (data) {
      if (!data) return null

      const [ano, mes, dia, horas, minutos, segundos] = data.split(/[-,T,:,Z,.]/)
      return `${dia}/${mes}/${ano} - ${horas}:${minutos}:${segundos}`
  },
  
  ptBrComHorario (data) {
    if (!data) return null

    const [ano, mes, dia, horas] = data.split(/[-, ]/)
    return `${dia}/${mes}/${ano} ${horas}`
  },

  horario (data) {
    if (!data) return null

    
    var [hora, minuto, segundo] = data.split(/[:, ]/)
  
    if(parseInt(hora)<10){ 
      hora = `0${parseInt(hora)}:`
    }else{
      hora = `${parseInt(hora)}:`
    }
    if(parseInt(minuto)<10){ 
      minuto = `0${parseInt(minuto)}:`
    }else{
      minuto = `${parseInt(minuto)}:`
    }
    if(parseInt(segundo)<10){ 
      segundo = `0${parseInt(segundo)}`
    }else{
      segundo = `${parseInt(segundo)}`
    }

    return `${hora}${minuto}${segundo}`
  },

  ptBrSemHorario (data) {
    if (!data) return null

    const [ano, mes, dia] = data.split(/[-, ]/)
    return `${dia}/${mes}/${ano}`
  },

formatoIsoData (data) {
  if (!data) return null
  const [ano, mes, dia] = data.split(/[/]/)
  return `${ano}-${mes}-${dia}`
},

formatoIsoDatasemHorario (data) {
  if (!data) return null
  const formatado = data.split(/[ ]/)
  return formatado[0]
},

  api (data) {
    if (!data) return null

    const [dia, mes, ano] = data.split('/')
    return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`
  },

}
