import _ from 'lodash'

export default (dados) => {
  const params = new URLSearchParams()

  _.map(dados, (value, key) => {
    if (Array.isArray(dados[key])) {
      _.map(value, item => params.append(key + '[]', item))
    } else {
      params.append(key, value ?? '')
    }
  })

  return params
}
